<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import axios from 'axios';
// import { required, email, minLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
// import Swal from "sweetalert2";
// import vSelect from 'vue-select'
// import { required, minValue } from "vuelidate/lib/validators";

export default {
    page: {
        title: "Cobranças",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Cobranças",
            items: [
                {
                    text: "Cobranças",
                    active: true
                }
            ],
            options: [],
            user: {},
            payload: {
                "initial_date": "2000-01-01",
                "final_date": "2000-01-01",
                "buyer": null,
                "salesman": null,
                "status": [1,2]
            },
            loading: false,
            installments: [],
            info: {
                parcels: 0,
                total: 0,
                tax: 0
            }
        };
    },
    validations: {},
    created(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.payload.initial_date = this.$moment().format('YYYY-MM-DD')
        this.payload.final_date = this.$moment().add(1, 'M').format('YYYY-MM-DD')
    },
    methods:{
        async fetchOptions (search, loading) {
            if (search.length < 1) return false;
            loading(true)
            let config = {
                method: 'GET',
                url: `${process.env.VUE_APP_API}api/v1/autocomplete/users?search=${search}`,
                headers: { 
                    'Accept': 'application/json', 
                    'Authorization': `Bearer ${this.user.access_token}`,
                }
            };
            await axios(config)
            .then( response => {
                this.options = []
                response.data.data.map( item => {
                    if (item.identity) {
                        this.options.push({label: `${item.name} (${item.identity})`, code: item.uuid})
                    }
                })

                loading(false)
            })

        },
        setSelected(){
            this.options = []
        },

        async search(){
            this.loading = true
            let payload = JSON.parse(JSON.stringify(this.payload))
            this.installments = []

            payload.salesman = payload.salesman ? payload.salesman.code : null
            payload.buyer = payload.buyer ? payload.buyer.code : null

            let config = {
                method: 'POST',
                url: `${process.env.VUE_APP_API}api/v1/admin/bill/report`,
                headers: { 
                    'Accept': 'application/json', 
                    'Authorization': `Bearer ${this.user.access_token}`,
                },
                data: payload
            };

            axios(config)
                .then( response => {
                    this.installments = response.data.data.installments
                    this.info = {
                        parcels:  response.data.data.count,
                        total:  response.data.data.total,
                        tax:  response.data.data.tax
                    }
                    this.loading = false
                })
        },

        async downloadXlsx(){
            this.loading = true
            let payload = JSON.parse(JSON.stringify(this.payload))

            payload.salesman = payload.salesman ? payload.salesman.code : null
            payload.buyer = payload.buyer ? payload.buyer.code : null
            payload.export = 'xlsx'

            let config = {
                method: 'POST',
                url: `${process.env.VUE_APP_API}api/v1/admin/bill/report`,
                headers: { 
                    'Accept': 'application/json', 
                    'Authorization': `Bearer ${this.user.access_token}`,
                },
                data: payload
            };

            axios(config)
                .then( response => {
                    window.open(response.data.data.xls, '_blank').focus();
                    this.loading = false
                })
        }
    },
    watch: {}
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-xl-3 col-lg-4">
                <div class="card">
                    <div class="card-header bg-transparent border-bottom">
                        <h5 class="mb-0">Filtros</h5>
                    </div>

                    <div class="card-body">
                        <h5 class="font-size-14 mb-3">Período</h5>

                        <b-form-group id="initial_date" label-cols-sm="2" label-cols-lg="2" label="De:" label-for="date">
                            <b-form-input id="date" type="date" v-model="payload.initial_date"></b-form-input>
                        </b-form-group>
                        <b-form-group id="final_date" label-cols-sm="2" label-cols-lg="2" label="Até:" label-for="date">
                            <b-form-input id="date" type="date" v-model="payload.final_date"></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="card-body">
                        <h5 class="font-size-14 mb-3">Status</h5>

                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="customCheck1" value="1" v-model="payload.status" />
                            <label class="custom-control-label" for="customCheck1">Em aberto</label>
                        </div>
                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="customCheck2" value="2" v-model="payload.status" />
                            <label class="custom-control-label" for="customCheck2">Pago</label>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label for="">Vendedor</label>
                            <v-select @search="fetchOptions" v-model="payload.salesman" @input="setSelected" :options="options">
                                <template #search="{attributes, events}">
                                    <input
                                    class="vs__search"
                                    v-bind="attributes"
                                    v-on="events">
                                    
                                </template>
                            </v-select>
                        </div>

                        <div class="form-group mt-4">
                            <label for="">Comprador</label>
                            <v-select @search="fetchOptions" v-model="payload.seller" @input="setSelected" :options="options">
                                <template #search="{attributes, events}">
                                    <input
                                    class="vs__search"
                                    v-bind="attributes"
                                    v-on="events">
                                    
                                </template>
                            </v-select>
                        </div>

                        <button class="btn btn-block btn-primary" @click="search">Filtrar</button>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="card">
                    
                    <b-overlay :show="loading" rounded="sm">
                        <div class="row p-4">
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <div class="media-body overflow-hidden">
                                                <p class="text-truncate font-size-14 mb-2">Parcelas</p>
                                                <h4 class="mb-0">{{info.parcels}}</h4>
                                            </div>
                                            <div class="text-primary">
                                                <i class="ri-wallet-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <div class="media-body overflow-hidden">
                                                <p class="text-truncate font-size-14 mb-2">Total</p>
                                                <h4 class="mb-0">R$ {{info.total | price}}</h4>
                                            </div>
                                            <div class="text-primary">
                                                <i class="ri-funds-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <div class="media-body overflow-hidden">
                                                <p class="text-truncate font-size-14 mb-2">Taxa</p>
                                                <h4 class="mb-0">R$ {{info.tax | price}}</h4>
                                            </div>
                                            <div class="text-primary">
                                                <i class="ri-shopping-bag-line font-size-24"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 px-4">
                                <ul class="list-inline user-chat-nav text-right mb-0">


                                    <li class="list-inline-item d-none d-sm-inline-block">
                                        <b-dropdown toggle-class="nav-btn" right variant="white">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-cog"></i>
                                            </template>
                                            <b-dropdown-item @click="downloadXlsx">Download XLSX</b-dropdown-item>
                                        </b-dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table
                                    class="table table-centered datatable dt-responsive nowrap"
                                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                                    >
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Vencimento</th>
                                            <th>Status</th>
                                            <th>Comprador</th>
                                            <th>Vendedor</th>
                                            <th>Descrição/lote</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in installments" :key="index" :class="$moment(item.due_date).isBefore($moment()) && item.status == 1 ? 'table-danger' : item.status == 2 ? 'table-success' : ''">
                                            <td>
                                                {{ item.due_date | date}}
                                            </td>
                                            <td>{{item.status_text}}</td>
                                            <td>{{item.bill.client.name}}</td>
                                            <td>{{item.bill.user.name}}</td>
                                            <td>{{item.bill.description}}</td>
                                            <td>R$ {{item.amount | price}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>


    </Layout>
</template>